import { useEffect, useState } from "react";

// begin hook
function useGetTrack(props, options, dependencies = []) {
  const [trackData, setTrackData] = useState(null);
  const { artist, songName } = props;
  console.log("artist useGetTrack:", artist);

  async function fetchSpotifySearchTracks(query, artist) {
    if (typeof window !== "undefined") {
      const resp = await fetch(`/.netlify/functions/SpotifySearchArtistTracks`, {
        method: "POST",
        // body: JSON.stringify({ query: query, artist: artist }),
        body: JSON.stringify({ query: query, artist: artist.artistData.name }),
      });

      const text = await resp.text();
      return JSON.parse(text);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (artist && songName) {
        let query = `"${songName}"`;
        let data = fetchSpotifySearchTracks(query, artist)
          .then((data) => {
            setTrackData(data);
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
    }
  }, [...dependencies]);

  // if (!artist || !songName) {
  if (!artist || !songName) {
    return null;
  }

  return trackData;
}

export default useGetTrack;

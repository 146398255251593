import { useEffect, useState } from "react";

// begin hook
function useGetOneAlbum(props, options, dependencies = []) {
  const [albumData, setAlbumData] = useState(null);
  const { artistId, albumName } = props;

  async function fetchSpotifyGetArtistAlbums(artistId, albumName) {
    if (!artistId || artistId === "undefined" || !albumName || albumName === "undefined") {
      return null;
    }
    if (typeof window !== "undefined") {
      console.log("calling fetchSpotifyGetArtistOneAlbum", artistId);

      const resp = await fetch(`/.netlify/functions/SpotifyGetArtistOneAlbum`, {
        method: "POST",
        body: JSON.stringify({ artist: `"${artistId}"`, album: `"${albumName}"` }),
      });

      const text = await resp.text();
      return JSON.parse(text);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined" && artistId !== "undefined" && albumName !== "undefined") {
      let data = fetchSpotifyGetArtistAlbums(artistId, albumName)
        .then((data) => {
          setAlbumData(data);
        })
        .catch((err) => {
          console.log("err:", err);
        });
    }
  }, [...dependencies]);

  if (!artistId || artistId === "undefined" || !albumName || albumName === "undefined") {
    return null;
  }

  return albumData;
}

export default useGetOneAlbum;

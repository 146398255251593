import React, { useEffect, useState } from "react";
import ReleaseLandingPageLoader from "../../components/loaders/ReleaseLandingPageLoader";
import Track404 from "../../components/notFoundPages/trackNotFound";
import ReleaseTemplate from "../../components/templates/ReleaseTemplate";
import useGetAlbums from "../../hooks/useGetAlbums";
import useGetArtist from "../../hooks/useGetArtist";
import useGetOneAlbum from "../../hooks/useGetOneAlbum";
import useGetTrack from "../../hooks/useGetTrack";
import { TypeOfWindowUndefined, cap, lc, removeHyphens } from "./../../components/_helpers";

const ReleaseLandingPage = (props) => {
  const [song, setSong] = useState(null);
  const [artistName, setArtistName] = useState(null);
  const [artistId, setArtistId] = useState(null);
  const [releaseName, setReleaseName] = useState(null);
  const [track, setTrack] = useState(null);
  const isAlbumsIndex = props.albumsIndex;
  const isAlbumPage = props.albumPage;

  useEffect(() => {
    let artist = props.params.artist;
    if (!artist || artist === "undefined") {
      artist = getDataFromURL("artist");
    }
    setArtistName(artist);

    let song = props.params.release;
    if (!song || song === "undefined") {
      song = getDataFromURL("albumName");
    }
    setReleaseName(removeHyphens(song));

    console.log("inside [release] useEffect", artist, song);
  }, []);

  const artistData = useGetArtist(
    {
      artistId: artistName,
    },
    null,
    [artistName]
  );
  console.log("artistData:", artistData);

  const getDataFromURL = (choice) => {
    console.log("inside getSongFromURL");
    if (TypeOfWindowUndefined()) {
      return null;
    }

    // Extract artistname and albumname from the URL in the browser
    const pathname = window.location.pathname;
    const parts = pathname.split("/").filter((part) => part !== ""); // Remove empty parts

    if (!isAlbumsIndex) {
      if (parts.length === 3) {
        // Assuming URL is domain.com/artistname/track/trackname
        if (choice === "artist") {
          return parts[0];
        }
        if (choice === "albumName") {
          return parts[2];
        }
      }
    } else {
      if (parts.length === 2) {
        // Assuming URL is domain.com/artistname/albumname
        if (choice === "artist") {
          return parts[0];
        }
        if (choice === "albumName") {
          return parts[1];
        }
      }
    }

    return null;
  };

  const trackData = isAlbumsIndex
    ? useGetAlbums(
        {
          artistId: artistName,
        },
        null,
        [artistName]
      )
    : isAlbumPage
    ? useGetOneAlbum(
        {
          artistId: artistName,
          albumName: releaseName,
        },
        null,
        [artistName, releaseName]
      )
    : useGetTrack(
        {
          // artistName: artistName,
          artist: artistData,
          songName: releaseName,
        },
        null,
        [artistData, releaseName]
      );

  console.log("trackData:", trackData);
  console.log("tracks:", track);

  const searchTracks = (searchResults) => {
    if (!searchResults) {
      return null;
    }
    let track = searchResults.find((track) => {
      return lc(track.album.name) === lc(track.name) && lc(track.album.name).includes(lc(releaseName)); // || lc(track.name) === songName;
    }); // || trackData.SpotifySearchArtistTracksData.spotify.search.tracks[0];

    if (!track) {
      track = searchResults.find((track) => {
        lc(track.name) === releaseName;
      });
    }

    if (!track) {
      track = trackData.tracks[0];
    }

    console.log("track:", track);
    return track;
  };

  useEffect(() => {
    if (trackData?.error) {
      console.log("error:", trackData.error);
      return;
    }

    if (trackData && artistName && releaseName && !isAlbumsIndex && !isAlbumPage) {
      // single tracks
      console.log("songName:", releaseName);
      let searchResults = trackData.tracks;

      // search results is array of objects. Find the array that has tracks[index].album.name === tracks[index].name
      let track = searchTracks(searchResults);
      setTrack(track);
      console.log("track:", track);

      if (track) {
        let song = {
          artist: artistName,
          artistUsername: artistName,
          artistBio: "Singer, Rapper, Producer",
          // featuredArtist: null,
          title: track.name,
          artwork: track.album.images[0].url,
          audioURL: track.preview_url,
          spotifyURL: track.external_urls.spotify,
          spotifyArtistURL: track.artists[0].external_urls.spotify,
          // appleMusicURL: "https://geo.music.apple.com/ca/album/_/1634706525?i=1634706736",
          // youtubeURL: "https://www.youtube.com/watch?v=clt-He56mOM",
          // facebookPixel: "530282730968435",
          trackData,
        };
        setSong(song);
      }
    } else if (isAlbumsIndex && trackData) {
      // all albums
      let song = {
        artist: artistName,
        artistUsername: artistName,
        artistBio: "Singer, Rapper, Producer",
        // featuredArtist: null,
        title: `Explore ${cap(artistName)}'s ${
          trackData.albums.filter((album) => album.album_group === "album").length
        } Albums`, // trackData.name,
        artwork: trackData?.albums[0]?.images[0].url, // trackData.images[0].url,
        audioURL: null, //trackData.tracks[0].preview_url,
        spotifyURL: "", //trackData.external_urls.spotify,
        spotifyArtistURL: trackData?.albums[0]?.artists[0]?.external_urls.spotify || "",
        // appleMusicURL: "https://geo.music.apple.com/ca/album/_/1634706525?i=1634706736",
        // youtubeURL: "https://www.youtube.com/watch?v=clt-He56mOM",
        // facebookPixel: "530282730968435",
        trackData,
      };
      setSong(song);
      setTrack(trackData.albums);
    } else if (isAlbumPage && trackData) {
      // single album
      let song = {
        artist: artistName,
        artistUsername: artistName,
        artistBio: "Singer, Rapper, Producer",
        // featuredArtist: null,
        title: trackData.album.name,
        artwork: trackData.album.images[0].url,
        audioURL: null, //trackData.tracks[0].preview_url,
        spotifyURL: trackData.album.external_urls.spotify,
        spotifyArtistURL: trackData.album.artists[0].external_urls.spotify,
        // appleMusicURL: "https://geo.music.apple.com/ca/album/_/1634706525?i=1634706736",
        // youtubeURL: "https://www.youtube.com/watch?v=clt-He56mOM",
        // facebookPixel: "530282730968435",
        trackData,
      };
      setSong(song);
      setTrack(trackData.tracks);
    }
  }, [trackData]);

  if (!trackData) {
    return <ReleaseLandingPageLoader />;
  }

  if (
    (!isAlbumsIndex && !isAlbumPage && trackData && !track) ||
    (isAlbumsIndex && trackData && !trackData.albums) ||
    (isAlbumPage && trackData && !trackData.album) ||
    (trackData && trackData && trackData.error)
  ) {
    return <Track404 song={{ artist: artistName, title: releaseName }} album />;
  }

  // if (trackData && track) {
  return (
    <ReleaseTemplate
      song={song}
      albumName={releaseName}
      albumsIndex={isAlbumsIndex}
      albumPage={isAlbumPage}
      artistData={artistData}
      showGrain
    />
  );
  // }
};

export default ReleaseLandingPage;
